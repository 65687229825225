<template>
  <div>
    <v-row>
      <v-spacer></v-spacer>

      <v-col cols="4">
        <v-text-field
          v-model="search"
          single-line
          append-icon="mdi-magnify"
          :label="$t('Search')"
          hide-details
          clearable
        >
          <v-icon slot="append"> mdi-magnify</v-icon></v-text-field
        >
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="getClients"
      :items-per-page.sync="itemsPerPage"
      :options.sync="options"
      class="elevation-1 ma-3 pa-4"
      hide-default-footer
      :no-data-text="$t('No data available')"
    >
      <template v-slot:[`item.action`]="{ item }">
        <email-buttons
          :item="item"
          :request="requestItems"
          :isAgents="false"
          @userFeedback="userFeedback"
        />
      </template>
      <template v-slot:footer>
        <v-row class="mx-2 py-2" align="center" justify="center">
          <v-spacer></v-spacer>

          <span class="mr-4 grey--text">
            <small
              >{{ $t("Page") }} {{ getPage }} {{ $t("out of") }}
              {{ getAmountOfPages }}</small
            >
          </span>
          <v-btn
            :disabled="hasPrevPage"
            fab
            x-small
            class="mr-1"
            @click="formerPage()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            :disabled="hasNextPage"
            fab
            x-small
            class="ml-1"
            @click="nextPage()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>
    <v-row>
      <v-snackbar v-model="alert" :color="alertType" dense :type="alertType">
        {{ alertText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="white" v-bind="attrs" @click="alert = false" icon>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </div>
</template>

<script>
import request from "../../request";
import helpers from "../../helpers";
export default {
  data() {
    return {
      options: {},
      clients: [],
      activationEmail: [],
      activationEmailDisable: [],
      search: "",
      page: 1,
      count: 10,
      start: 0,
      amountOfPages: 1,
      activeAccount: [],
      itemsPerPage: 10,
      requestItems: request,
      alert: false,
      alertType: "success",
      alertText: "",
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Clientcode"),
          align: "start",
          sortable: true,
          value: "Code",
        },
        {
          text: this.$t("Customer"),
          sortable: false,
          value: "Name",
        },
        {
          text: this.$t("Email"),
          sortable: false,
          value: "Email",
        },
        {
          text: this.$t("Last modified"),
          sortable: false,
          value: "LastModified",
        },
        {
          text: this.$t("Actions"),
          value: "action",
          sortable: false,
        },
      ];
    },
    getPage: {
      get() {
        return this.page;
      },
      set(newItem) {
        this.page = newItem;
      },
    },
    hasPrevPage() {
      if (this.getPage == 1) {
        return true;
      }
      return false;
    },
    hasNextPage() {
      if (this.getPage < this.amountOfPages) {
        return false;
      }
      return true;
    },
    getAmountOfPages: {
      get() {
        if (this.amountOfPages == 0) {
          return 1;
        }
        return this.amountOfPages;
      },
      set(newItem) {
        this.amountOfPages = newItem;
      },
    },
    getClients() {
      return this.clients;
    },
  },
  created() {
    this.getCustomers({
      start: 0,
      count: this.count,
      search: "",
      sort: "",
      sortDescending: false,
    });
  },
  watch: {
    search: function (params) {
      params ? params : (params = "");
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.resetPaging();
        this.getCustomers({
          start: this.start,
          count: this.count,
          search: params,
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      }, 800);
    },
    options: {
      handler() {
        this.getCustomers({
          start: this.start,
          count: this.count,
          search: this.search ? this.search : "",
          sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
          sortDescending:
            this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
        });
      },
      deep: true,
    },
  },
  methods: {
    resetPaging() {
      this.page = 1;
      this.start = 0;
      this.count = 10;
    },
    toggleClientActivity(item) {
      request.put(
        "/api/kdesign/toggle-customer-role?role=B2B&customerId=" + item.Id,
        null,
        () => {
          return;
        }
      );
    },
    getCustomers(items) {
      request.get(
        "/api/kdesign/customers?start=" +
          items.start +
          "&count=" +
          items.count +
          "&sort=" +
          items.sort +
          "&sortDescending=" +
          items.sortDescending +
          "&search=" +
          items.search,
        null,
        (res) => {
          this.clients = [];

          res.forEach((element, index) => {
            res[index].LastModified = helpers.changeTimeToReadeble(
              element.LastModified
            );
            res[index]["HasAccessToSalesAgent"] = element.IsSalesAgent;
            this.clients.push(element);
          });
        }
      );
      this.getCustomersCount(items);
    },
    getCustomersCount(items) {
      request.get(
        "/api/kdesign/customerscount?search=" + items.search,
        null,
        (res) => {
          this.amountOfPages = Math.ceil(res.item / this.itemsPerPage);
        }
      );
    },
    nextPage() {
      this.page++;
      this.start = this.start + this.itemsPerPage;
      this.getCustomers({
        start: this.start,
        count: this.count,
        search: this.search ? this.search : "",
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
    checkForSearch() {
      return this.search !== "" ? `?search=${this.search}` : "";
    },
    formerPage() {
      this.page--;
      this.start = this.start - this.itemsPerPage;
      this.getCustomers({
        start: this.start,
        count: this.count,
        search: this.search ? this.search : "",
        sort: this.options.sortBy.length > 0 ? this.options.sortBy[0] : "",
        sortDescending:
          this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false,
      });
    },
    userFeedback(event) {
      this.alert = event.alert;
      this.alertType = event.alertType;
      this.alertText = event.alertText;
    },
  },
};
</script>

<style>
</style>